














import Vue from 'vue';
import { CASE_STATUS } from '@/constant/constants';
import { getTabsHelper } from '@/helper/functions';

export default Vue.extend({
  name: 'tabs',
  props: ['caseStatus', 'insuranceKey', 'tabId', 'activeTab'],
  watch: {
    $route: 'routeChange',
    insuranceKey: {
      immediate: true,
      handler(value) {
        if (value) {
          this.tabs = getTabsHelper(value);
          // this.routePushActiveTab();
        } else {
          this.tabs = getTabsHelper(value);
        }
      },
    },
    tabId: {
      immediate: true,
      handler(value) {
        if (this.insuranceKey) {
          this.localActiveTab = value;
          this.tabs = getTabsHelper(this.insuranceKey);
          this.routePushActiveTab();
        }
      },
    },
  },
  data() {
    return {
      localActiveTab: 0,
      tabs: [
        {
          id: 1,
          name: 'Risikoerfassung',
          path: 'transport-insurance',
        },
        {
          id: 2,
          name: 'Preis',
          path: 'transport-insurance-price-card',
        },
        {
          id: 3,
          name: 'Kundendaten',
          path: 'transport-insurance-customer-data',
        },
        {
          id: 4,
          name: 'Vorschlag / Police',
          path: 'transport-insurance-proposal-policy',
        },
        {
          id: 5,
          name: 'Bedingungen / Dateien',
          path: '/transport-insurance/conditions-files',
        },
      ],
    };
  },
  methods: {
    onTabClick(path: string, index: number, tabId: number) {
      this.$emit('clicked', index);
      if (
        ((this.caseStatus === CASE_STATUS.SUGGESTION ||
        this.caseStatus === CASE_STATUS.CONTRACT ||
        tabId <= this.tabId) && this.caseStatus !== CASE_STATUS.DRAFT) || (tabId === 5) || (tabId === 1)// && this.localActiveTab >= 1
      ) {
        this.$router.push({
          name: path,
        }).catch((err) => {
          throw new Error(`Problem handling something: ${err}.`);
        });
      }
    },
    routeChange() {
      this.tabs.forEach((tab, index: number) => {
        if (this.$route.name && (this.$route.name === tab.path || this.$route.path === tab.path)) {
          this.localActiveTab = index;
        }
      });
    },
    routePushActiveTab() {
      if (this.$route.name !== this.tabs[this.localActiveTab].path) {
        this.$router.push({
          name: this.tabs[this.localActiveTab].path,
        }).catch((err) => {
          throw new Error(`Problem handling something: ${err}.`);
        });
        this.routeChange();
      }
    },
  },
  mounted() {
    this.routeChange();
    this.localActiveTab = this.tabId;
  },
  beforeUpdate() {
    this.tabs = getTabsHelper(this.insuranceKey);
  },
});
